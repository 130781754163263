<template>
    <v-container fluid class="px-6 py-8">
        <v-progress-linear v-if="loading" slot="progress" color="primary" indeterminate></v-progress-linear>
        <message :message="message"></message>
    </v-container>
</template>

<script>
import message from "../components/message.vue";
import { apolloClient } from "@/apollo";
import gql from "graphql-tag";

export default {
    components: {
        message
    },
    computed: {

    },
    data: () => ({
        loading: false,
        message: {}
    }),
    async mounted() {
        try {
            this.loading = true
            const query = gql`
                query MyQuery($msg_id: bigint!) {
                    database_messages_by_pk(msg_id: $msg_id) {
                        from_user
                        message
                        msg_date
                        recipient_user
                        title
                        msg_id
                    }
                }
            `;
            const { data } = await apolloClient.query( { query: query ,variables: { msg_id: this.$route.params.msg_id }} );
            this.message = data.database_messages_by_pk;
        } catch(e) {
            console.log(e)
        } finally {
            this.loading = false
        }
    },
    methods: {

    },
};
</script>
