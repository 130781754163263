import { make } from "vuex-pathify";
import { apolloClient } from "@/apollo";
import gql from "graphql-tag";

const getDefaultState = () => {
    return {
        users: [],
        messages: [],
        limit: 30,
        offset: 0,
        count: 0,
        filter: {
            message: null,
            recipient_id: null,
            from_id: null,
        },
        loading: false,
    };
};

const state = getDefaultState();

const getters = {};

const mutations = {
    ...make.mutations(state),
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    async getUsers({ commit }) {
        const query = gql`
            {
                database_m_users {
                    id
                    name
                    msg_total
                }
            }
        `;
        const { data } = await apolloClient.query({ query: query });
        commit("users", data.database_m_users);
    },
    async getMessages({ commit, state }) {
        
        let whereVars = {};
        let whereQL = ``;

        Object.keys(state.filter).forEach(key => {
            if (state.filter[key] != null) {
                if (key == 'message') {
                    if (state.filter[key] != '') {
                        whereVars[key] = "%"+state.filter[key]+"%";
                        whereQL += `\n ${key}: {_ilike: $${key}}`
                    }
                } else {
                    whereQL += `\n ${key}: {_eq: $${key}}`
                    whereVars[key] = state.filter[key];
                }
                
            }
        });

        
        const query = gql`
            query MyQuery($limit: Int, $offset: Int, $from_id: Int, $recipient_id: Int, $message: String) {
                database_messages_aggregate(where: {${whereQL}}) {
                    aggregate {
                        count
                    }
                }
                database_messages(limit: $limit, offset: $offset, where: {${whereQL}}) {
                    from_user
                    message
                    msg_date
                    recipient_user
                    title
                    msg_id
                }
            }
        `;
        try {
            commit("loading", true);
            const { data } = await apolloClient.query({
                query: query,
                variables: { 
                    limit: state.limit, 
                    offset: state.offset,
                    ...whereVars
                },
            });
    
            commit("messages", data.database_messages);
            commit("count", data.database_messages_aggregate.aggregate.count);
        } catch(e) {
            console.log(e)
        } finally {
            commit("loading", false);
        }
    },
};

export default {
    strict: false,
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
