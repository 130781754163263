<template>
    <v-card class="py-0">
        <div class="d-flex justify-space-between align-center">
            <div>
                <v-card-title class="primary--text px-0 mb-0 pb-0">{{message.title}}</v-card-title>
                <span class="mb-4" style="font-size:11px;color:#cfcfcf">Изпратено от <b class="text-secondary" v-html="message.from_user"></b> до
                    <b class="text-secondary" v-html="message.recipient_user"></b> на
                    <b class="text-secondary" v-html="timeStamp(message.msg_date)"></b>
                </span>
            </div>
            <v-btn v-if="inlist" :to="{ name: 'Single', params: { msg_id: message.msg_id } }" icon color="primary"><v-icon>mdi-eye</v-icon></v-btn>
        </div>

        <v-divider />
        <v-card-text class="px-0">
            <span v-html="bbparse(message.message, highlight)"></span>
        </v-card-text>
    </v-card>
</template>
<script>
import yabbcode from "ya-bbcode";

export default {
    props:{
        message: {
            type: Object,
            required: true
        },
        highlight: {
            type: String,
            default: ''
        },
        inlist: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        bbparse(value, highlight) {
            if (!value) return "";
            let parser = new yabbcode();
            if (highlight != null && highlight != '') {
                const regex = new RegExp(highlight, 'gi');
                let text = value;
                text = text.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
                const newText = text.replace(regex, '<mark class="highlight">$&</mark>');
                return parser.parse(newText);
            } else {
                return parser.parse(value);
            }
            
        },
        timeStamp(timestamp) {
            var a = new Date(timestamp * 1000);
            var today = new Date();
            var yesterday = new Date(Date.now() - 86400000);
            var months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
            var year = a.getFullYear();
            var month = months[a.getMonth()];
            var date = a.getDate();
            var hour = a.getHours();
            var min = a.getMinutes();
            if (a.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0))
                return "today, " + hour + ":" + min;
            else if (a.setHours(0, 0, 0, 0) == yesterday.setHours(0, 0, 0, 0))
                return "yesterday, " + hour + ":" + min;
            else if (year == today.getFullYear())
                return date + " " + month + ", " + hour + ":" + min;
            else
                return (
                    date + " " + month + " " + year + ", " + hour + ":" + min
                );
        }
    }
};
</script>
