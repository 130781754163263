<template>
    <v-container fluid class="px-6 py-8">
        <v-row class="mb-3">
            <v-col cols="12" md="4">
                <v-text-field v-model="filter.message" @click:clear="page = 1, offset = 0, filter.message = null, $store.dispatch('messages/getMessages')" outlined dense chips label="Търси (хой, майкатишеа и тк.н.)" clearable style="background:#2b2b2b" hide-details></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-autocomplete v-model="filter.from_id" @click:clear="page = 1, offset = 0, filter.from_id = null, $store.dispatch('messages/getMessages')" :items="users" outlined dense label="От кълтака" item-text="name" item-value="id" clearable style="background:#2b2b2b" hide-details></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
                <v-autocomplete v-model="filter.recipient_id" @click:clear="page = 1, offset = 0, filter.recipient_id = null, $store.dispatch('messages/getMessages')" :items="users" outlined dense label="До кълтака" item-text="name" item-value="id" clearable style="background:#2b2b2b" hide-details></v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn @click="page = 1, offset = 0, $store.dispatch('messages/getMessages')" color="primary" block style="top:1px">
                    <v-icon class="mr-3">mdi-magnify</v-icon>
                    <span style="background:#fff; padding:2px 10px;border-radius: 5px;color:#000">{{ count }}</span>
                </v-btn>
            </v-col>
        </v-row>

        <div v-if="messages.length > 0" class="d-flex justify-center px-6">
            <v-pagination  class="custom-pagination" v-model="page" :length="totalPages" :total-visible="15" @input="paginate"></v-pagination>
        </div>

        <div class="mt-6">
            <v-progress-linear v-if="loading" slot="progress" color="primary" indeterminate></v-progress-linear>
            <div style="position:relative" >
                <template v-for="(message, index) in messages">
                    <message :message="message" :highlight="filter.message" :key="index" inlist></message>
                </template>
                <v-card v-if="messages.length == 0">
                    Курец бате нема никой !
                </v-card>
                <v-overlay :value="loading" opacity="0.6" absolute />
            </div>
        </div>


        <div v-if="messages.length > 0" class="d-flex justify-center px-6">
            <v-pagination class="custom-pagination my-4" v-model="page" :length="totalPages" :total-visible="15" @input="paginate"></v-pagination>
        </div>
    </v-container>
</template>

<script>
import { get, sync, call } from "vuex-pathify";
import { TrinityRingsSpinner } from "epic-spinners";
import message from "../components/message.vue";

export default {
    components: {
        TrinityRingsSpinner,
        message
    },
    computed: {
        ...sync('messages/*'),
        totalPages() {
            return Math.ceil(this.count / this.limit);
        },
    },
    data: () => ({
        page: 1,
    }),
    async mounted() {
        await this.$store.dispatch("messages/getMessages");
    },
    methods: {
        async paginate() {
            this.offset = (this.page - 1) * this.limit;
            await this.$store.dispatch("messages/getMessages");
        }
    },
};
</script>
