<template>
    <v-app dark>
        <v-main>
            <div class="d-flex justify-center">
                <img @click="if ($route.name == 'Single') $router.push('/')" src="@/assets/359logo.png" style="width:100%;max-width:480px;cursor:pointer;" />
            </div>
            <router-view />
        </v-main>
        <v-overlay :value="loading" opacity="1">
            <div class="d-flex flex-column align-center justify-center">
                <trinity-rings-spinner :animation-duration="1500" :size="96" :color="'#fc9400'" />
                <div class="mt-6">Loading</div>
            </div>
        </v-overlay>
    </v-app>
</template>

<script>
import { TrinityRingsSpinner } from 'epic-spinners'
import logo from '@/assets/359logo.png'

export default {
    name: "App",
    components: {
        TrinityRingsSpinner
    },
    data: () => ({
        loading: true
    }),
    async mounted() {
        this.loading = true;
        this.$vuetify.theme.dark = true;
        await this.$store.dispatch("messages/getUsers");
        this.loading = false;
    },
};
</script>
<style>
br {
    height:10px !important;
}
.theme--dark.v-application {
    background: #555 !important;
}
.theme--dark.v-card {
    background: #484848 !important;
    padding: 15px 30px;
    border: 1px solid #666 !important;
    font-size: 13px;
    line-height: 20px !important;
    margin-bottom: 15px;
    border-radius: 4px;
    box-shadow: none !important;
}
.custom-pagination .theme--dark.v-pagination .v-pagination__item,
.custom-pagination .theme--dark.v-pagination .v-pagination__navigation {
    background:#2b2b2b;
    box-shadow: none !important;
}
blockquote {
    padding: 5px 10px;
    font-size: 12px;
    background: #3b3b3b;
    border-radius: 4px;
}
</style>
