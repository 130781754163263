import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import TextHighlight from 'vue-text-highlight';
Vue.component('text-highlight', TextHighlight);
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      dark: {
        primary: '#fc9400',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
});
