import Vue from "vue";
import Vuex from "vuex";
import pathify from "./pathify";
// import the auto exporter
import modules from './modules';

Vue.use(Vuex);

const getDefaultState = () => {};

const state = getDefaultState();

const getters = {};

const mutations = {};

const actions = {};

export default new Vuex.Store({
    plugins: [pathify.plugin],
    state,
    mutations,
    actions,
    modules
});
